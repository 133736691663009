const isFirstVisit = (event) => {
  const visitKey = `visited_${event}`;
  const sessionKey = "session_id";

  // Check if it's a new session
  let sessionId = sessionStorage.getItem(sessionKey);
  if (!sessionId) {
    sessionId = Date.now().toString();
    sessionStorage.setItem(sessionKey, sessionId);
  }

  // Check if this event has been visited in this session
  if (
    !localStorage.getItem(visitKey) ||
    localStorage.getItem(visitKey) !== sessionId
  ) {
    localStorage.setItem(visitKey, sessionId);
    return true;
  }

  return false;
};

export const pageview = (event) => {
  if (isFirstVisit(event)) {
    window.gtag("config", "G-LH6J6H5ZDP", {
      page_title: `${event} View`,
      page_path: `/${event}`,
    });
    console.log(`Pageview sent for event: ${event}`);
  } else {
    console.log(`Pageview not sent - not first visit for event: ${event}`);
  }
};

export const event = ({ action, category, label, value }) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
