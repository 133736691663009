import React, { useEffect, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { DirectionalLightHelper } from "three";

const DirectionalLightWithHelper = ({
  hide = false,
  position,
  intensity,
  color = 0xffffff,
  targetPosition,
}) => {
  const lightRef = useRef();
  const targetRef = useRef();
  const { scene } = useThree();

  useEffect(() => {
    const light = lightRef.current;
    if (light && targetRef.current) {
      light.target = targetRef.current;
      scene.add(light.target);
      scene.add(light);
    }
  }, [scene, targetPosition]); // Ensure updates if the target position changes

  useEffect(() => {
    if (lightRef.current) {
      const helper = new DirectionalLightHelper(lightRef.current, 5, color);
      if (!hide) scene.add(helper);

      return () => {
        scene.remove(helper);
      };
    }
  }, [scene, position, intensity, color]); // Re-run effect if these props change

  useFrame(() => {
    if (targetRef.current) {
      targetRef.current.position.set(...targetPosition);
    }
  });

  return (
    <>
      <directionalLight
        castShadow
        ref={lightRef}
        position={position}
        intensity={intensity}
        color={color}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-camera-left={-500}
        shadow-camera-right={500}
        shadow-camera-top={500}
        shadow-camera-bottom={-500}
        shadow-bias={-0.001}
      />
      {/* Invisible mesh that acts as the target */}
      <mesh ref={targetRef} position={targetPosition} visible={false}>
        <sphereBufferGeometry args={[0.5, 16, 16]} />
        <meshStandardMaterial color="red" />
      </mesh>
    </>
  );
};

export default DirectionalLightWithHelper;
