import React, { Suspense, useState, useRef, useEffect } from "react";
import QueryExtractor from "./Components/QueryExtractor";
import Car from "./Components/Car";
import { config } from "./config/config-f1";
import { patternState, state } from "./utils/tools";
import { getTranslation as T } from "./utils/translations";
import { event as gaEvent, pageview } from "./utils/analytics";

//branding
import FranceBackground from "./Components/Resources/FranceBackground.jpeg";
import FranceUpperLogo from "./Components/Resources/FranceUpperLogo.png";
import FranceLowerLogo from "./Components/Resources/FranceLowerLogo.png";
import AustinBackground from "./Components/Resources/AustinBackground.png";
import AustinUpperLogo from "./Components/Resources/AustinUpperLogo.png";
import AustinLowerLogo from "./Components/Resources/AustinLowerLogo.png";
import VegasLowerLogo from "./Components/Resources/VegasLowerLogo.png";
// import NewMexBackground from "./Components/Resources/NewMexBackground.jpeg";
// import NewMexUpperLogo from "./Components/Resources/NewMexUpperLogo.png";
// import NewMexLowerLogo from "./Components/Resources/NewMexLowerLogo.png";

const App = () => {
  const {
    model,
    modelColors,
    graphics,
    graphicColors,
    locale,
    printerFilename,
    event,
  } = QueryExtractor();
  const [isRecording, setIsRecording] = useState(false);
  const [file, setFile] = useState(null);
  const canvasRef = useRef();
  const loadingBarRef = useRef();

  useEffect(() => {
    // Update title
    if (event) {
      document.title = `${event} Livery`;
    }

    // Log pageview with the specific event
    pageview(event);
  }, [event]);

  let background = FranceBackground;
  let upperLogo = FranceUpperLogo;
  let lowerLogo = FranceLowerLogo;
  let style = "France";

  if (event === "Austin") {
    background = AustinBackground;
    upperLogo = AustinUpperLogo;
    lowerLogo = AustinLowerLogo;
    style = "Austin";
  } else if (event === "NewMex") {
    // background = NewMexBackground;
    // upperLogo = NewMexUpperLogo;
    // lowerLogo = NewMexLowerLogo;
    // style = "Austin";
  } else if (event === "Vegas") {
    background = AustinBackground;
    upperLogo = AustinUpperLogo;
    lowerLogo = VegasLowerLogo;
    style = "Austin";
  }

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   if (canvas) {
  //     canvas.width = 1920;
  //     canvas.height = 1080;
  //   }
  // }, []);

  const captureFrames = async () => {
    gaEvent({
      action: "Saved Video",
      category: "User",
      label: `${event} Saves`,
    });

    if (file) {
      shareFile();
    } else {
      const canvas = canvasRef.current;
      if (!canvas) return;

      setIsRecording(true);
      loadingBarRef.current.style.width = "0";

      setTimeout(() => {
        loadingBarRef.current.style.width = "100%";
      }, 100);

      setTimeout(async () => {
        const stream = canvas.captureStream(20);
        let options = {
          mimeType: "video/webm; codecs=vp9",
          bitsPerSecond: 50000000,
        };

        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          options.mimeType = "video/mp4";
        }

        const recorder = new MediaRecorder(stream, options);
        let chunks = [];

        recorder.ondataavailable = (e) => chunks.push(e.data);
        recorder.onstop = async () => {
          const blob = new Blob(chunks, { type: options.mimeType });
          const file = new File([blob], "f1-livery.mp4", { type: blob.type });
          setFile(file);
        };

        recorder.start();
        setTimeout(() => recorder.stop(), 6050);
      }, 1000);
    }
  };

  const handleShare = async (e) => {
    e.preventDefault();

    try {
      await shareFile();
      console.log("Video shared successfully");
    } catch (error) {
      console.error("Error sharing video:", error);
    }
  };

  const shareFile = async () => {
    if (file && navigator.canShare && navigator.canShare({ files: [file] })) {
      try {
        await navigator.share({
          files: [file],
          title: T(locale, "videoTitle"),
          text: T(locale, "videoText"),
        });
        console.log("Sharing was successful.");
        setIsRecording(false);
      } catch (error) {
        console.error("Sharing failed:", error);
        downloadFile(file, "f1-livery.webm");
        setIsRecording(false);
      }
    } else {
      downloadFile(file, "f1-livery.webm");
      setIsRecording(false);
    }
  };

  const downloadFile = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // ReactGA.send({
  //   hitType: "pageview",
  //   page: `/${event}`,
  //   title: `${event} Views`,
  // });

  return (
    <div className="App">
      <h1 className="printerId">{printerFilename}</h1>
      <img src={background} className={`${style}Background`} alt="Background" />
      <img src={upperLogo} className={`${style}UpperLogo`} alt="Logo" />
      <img src={lowerLogo} className={`${style}LowerLogo`} alt="Logo" />
      <div className={`overlay ${isRecording ? "show" : ""}`}>
        {file && isRecording ? (
          <div className="overlay-content">
            <h1 className="overlay-title">{T(locale, "completeTitle")}</h1>
            <a className="share-link" href="#" onClick={handleShare}>
              {T(locale, "completeButton")}
            </a>
          </div>
        ) : (
          <div className="overlay-content">
            <h1 className="overlay-title">{T(locale, "loadingTitle")}</h1>
            <div className={`loading-bar-container`}>
              <div className="loading-bar" ref={loadingBarRef}></div>
            </div>
            <h2 className="overlay-text">{T(locale, "loadingNote")}</h2>
          </div>
        )}
      </div>
      <Suspense
        fallback={<div className="loading">{T(locale, "analyzing")}</div>}
      >
        <button onClick={captureFrames} className={`${style}Button`}>
          {T(locale, "shareButton")}
        </button>
        <div className={isRecording ? "recording" : "viewing"}>
          <Car
            ref={canvasRef}
            config={config}
            state={state}
            patternState={patternState}
            model={model}
            modelColors={modelColors}
            graphics={graphics}
            graphicColors={graphicColors}
            isRecording={isRecording}
          />
        </div>
      </Suspense>
    </div>
  );
};

export default App;
