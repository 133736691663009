import React, { useRef, useEffect, forwardRef, useState } from "react";
import { useGLTF, OrbitControls, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { Canvas, useFrame, useThree, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import { deg2rad, texture } from "../utils/tools";
import DirectionalLightWithHelper from "../Components/DirectionalLightWithHelper";
import BackgroundImage from "../Components/Resources/VideoBackground.png";

function CameraAdjuster() {
  const { camera, size } = useThree();

  useEffect(() => {
    const aspectRatio = size.width / size.height;
    const distance = aspectRatio < 1 ? 5 / aspectRatio : 5;
    camera.position.z = distance;
    camera.far = 100;
    camera.updateProjectionMatrix();
  }, [camera, size.width, size.height]);

  return null;
}

function BackgroundPlane() {
  const texture = useLoader(THREE.TextureLoader, BackgroundImage);

  const planeWidth = 1080 / 100;
  const planeHeight = 1920 / 100;

  return (
    <mesh position={[0, 0, -10]}>
      <planeBufferGeometry attach="geometry" args={[planeWidth, planeHeight]} />
      <meshBasicMaterial
        attach="material"
        map={texture}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
}

const SpinningCar = ({
  model,
  modelColors,
  graphics,
  CAR,
  patternState,
  state,
  graphicColors,
}) => {
  const ref = useRef();
  const { scene, nodes, materials } = useGLTF(model);

  useEffect(() => {
    if (ref.current) {
      // ref.current.rotation.z = 20 * (Math.PI / 180);
      ref.current.rotation.x = 15 * (Math.PI / 180);
    }

    return () => {
      Object.values(materials).forEach((material) => material.dispose());
    };
  }, [materials, modelColors, scene, graphics]);

  patternState.current = graphics;
  useFrame((state, delta) => {
    const fullRotationPerSecond = (2 * Math.PI) / 6;
    ref.current.rotation.y += fullRotationPerSecond * delta;
  });

  const mesh = Object.keys(nodes).filter((key) => nodes[key].type === "Mesh");
  const meshItems = mesh.map((meshItem, index) => {
    const materialName = Object.keys(materials)[index];
    const isLogoLayer = CAR.logoMeshItems.includes(meshItem);
    const isConfigurable = CAR.meshItems.includes(meshItem);
    const isFixed = CAR.fixedMeshItems.includes(meshItem);
    const tires = meshItem === "NONCONFIGTIRES";
    if (isLogoLayer && !patternState.current) return
    const materialProps = {
      opacity: 1,
      transparent: false,
      map: materials[materialName].map,
      metalness: 0.7,
      roughness: 0.2,
    };

    const queryColor = modelColors[materialName];
    if (queryColor) {
      materialProps.color = new THREE.Color(queryColor);
    }

    if (isLogoLayer) {
      materialProps.transparent = true;
      materialProps.alphaTest = 0.5;
      materialProps.side = THREE.DoubleSide;
    }

    if (isLogoLayer && patternState.current) {
      const p = texture(patternState.current);
      p.flipY = false;
      materialProps.color = new THREE.Color(graphicColors);
      materialProps.map = p;
    }

    if (isConfigurable) {
      const stateColor = state.items[CAR.materials[meshItem]];
      const finalColor = queryColor || stateColor;
      materialProps.color = new THREE.Color(finalColor);
    }

    if (isFixed) {
      materialProps.transparent = true;
      materialProps.alphaTest = 0.5;
      materialProps.side = THREE.DoubleSide;
      let p = texture("Graphic_Car_UV_Layout_American_Express.png");
      p.flipY = false;
      materialProps.map = p;
    }

    if (tires) {
      const p = texture("TIRES.png");
      p.flipY = false;
      materialProps.map = p;
      materialProps.metalness = 0.8;
      materialProps.roughness = 0.2;
    }

    return (
      <mesh
        key={index}
        receiveShadow
        castShadow
        geometry={nodes[meshItem].geometry}
      >
        <meshStandardMaterial {...materialProps} />
      </mesh>
    );
  });

  return (
    <group
      object={scene}
      ref={ref}
      scale={[0.01, 0.01, 0.01]}
      position={[0, -0.4, 0]}
    >
      {meshItems}
    </group>
  );
};

const Car = forwardRef(
  (
    {
      state,
      config,
      patternState,
      model,
      modelColors,
      graphics,
      graphicColors,
      isRecording,
    },
    ref
  ) => {
    const { CAR } = config;
    const orbitControlsRef = useRef();
    const [adjustSize, setAdjustSize] = useState(false);
    const initialCameraPosition = [0, 0, 10];

    useEffect(() => {
      let timer;
      if (isRecording) {
        timer = setTimeout(() => {
          setAdjustSize(true);
          if (orbitControlsRef.current) {
            orbitControlsRef.current.enabled = false;
            orbitControlsRef.current.object.position.set(
              ...initialCameraPosition
            );
            orbitControlsRef.current.object.lookAt(0, 0, 0);
          }
        }, 700);
      } else {
        setAdjustSize(false);
        if (orbitControlsRef.current) {
          orbitControlsRef.current.enabled = true;
        }
      }

      return () => clearTimeout(timer);
    }, [isRecording]);

    return (
      <Canvas
        ref={ref}
        camera={{ position: initialCameraPosition, fov: 50 }}
        dpr={[1, 2]}
      >
        <directionalLight position={[0, 500, 150]} intensity={1} />
        <directionalLight position={[0, 50, 30]} intensity={0.9} />
        <DirectionalLightWithHelper
          hide
          targetPosition={[0, 0, 0]}
          position={[0, -150, 30]}
          intensity={0.9}
        />
        <ambientLight position={[0, -500, -150]} intensity={2.9} />
        {adjustSize && <BackgroundPlane />}
        <SpinningCar
          model={model}
          modelColors={modelColors}
          graphics={graphics}
          CAR={CAR}
          patternState={patternState}
          state={state}
          graphicColors={graphicColors}
        />
        <CameraAdjuster />
        {!adjustSize && (
          <OrbitControls
            ref={orbitControlsRef}
            enableZoom={false}
            enablePan={false}
            enableRotate={true}
          />
        )}
      </Canvas>
    );
  }
);

export default Car;
