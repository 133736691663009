const translations = {
  en: {
    analyzing: "ANALYZING LIVERY",
    shareButton: "SHARE VIDEO",
    loadingTitle: "EXPORTING",
    loadingNote: "THIS MAY TAKE A MOMENT",
    completeTitle: "COMPLETE",
    completeButton: "SHARE NOW",
    videoTitle: "Check out my creation!",
    videoText: "Take a look at my livery made with Amex",
  },
  fr: {
    analyzing: "ANALYSE DE LA LIVRÉE",
    shareButton: "PARTAGER LA VIDÉO",
    loadingTitle: "EXPORTATION",
    loadingNote: "CE PROCESSUS PEUT PRENDRE UN MOMENT",
    completeTitle: "TERMINÉ",
    completeButton: "PARTAGER MAINTENANT",
    videoTitle: "Regardez ce que j’ai créé",
    videoText: "Jetez un coup d’œil à la livrée que j’ai créé avec Amex",
  },
};

export const getTranslation = (language, key) => {
  return translations[language][key];
};
