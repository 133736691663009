import { graphics as graphicsList } from "../utils/tools";

const paramToMaterialMap = {
  IN1: "INTAKE.001",
  FW1: "FRONTWING.001",
  FL1: "FLOOR.001",
  WH1: "WHEELS.001",
  RW1: "REARWING.001",
  BO1: "BODY.001",
  BO2: "BODY.002",
};

const QueryExtractor = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const encodedParams = params.get("id");
  let decodedParams = {
    state: {},
    patternState: "THEME_01_Base.png",
    locale: "en",
    printerFilename: null,
    event: "France",
  };
  if (encodedParams) {
    try {
      const jsonString = atob(encodedParams);
      decodedParams = JSON.parse(jsonString);
    } catch (e) {
      console.error(e);
    }
  }
  const model = "f1.glb";

  const graphics = graphicsList[params.get("G")] || "THEME_01_Base.png";
  const graphicColors =
    (decodedParams &&
      decodedParams.state &&
      decodedParams.state["BODY_LOGO.001"]) ||
    "#ffffff";
  const designatedLocale = decodedParams.locale || "en";
  const designatedEvent = decodedParams.event || "France";

  // const path = window.location.pathname;
  // const events = ["Austin", "France", "NewMex", "Vegas"];
  // const event = events.find((route) =>
  //   path.includes(`/${route.toLowerCase()}/`)
  // );

  console.log("modelColors:", decodedParams.state);
  console.log("graphics:", decodedParams.patternState);
  console.log("graphicColors:", graphicColors);
  console.log("locale:", decodedParams.locale);
  console.log("printerFilename:", decodedParams.printerFilename);
  console.log("event:", decodedParams.event);

  return {
    model,
    modelColors: decodedParams.state,
    graphics: decodedParams.patternState,
    graphicColors,
    locale: designatedLocale,
    printerFilename: decodedParams.printerFilename,
    event: designatedEvent,
  };
};

export default QueryExtractor;
