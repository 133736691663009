// utils/tools.js
import * as THREE from "three";
import BackgroundImage from "../Components/Resources/WebBackground.png";
import { proxy } from "valtio";
import { config } from "../config/config-f1";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";

export const cars = {
  1: "f1.glb",
};

export const videos = {
  1: "IdleAnimA",
  2: "IdleAnimA",
};

export const graphics = [
  "THEME_01_Base.png",
  "THEME_02_Base.png",
  "THEME_03_Base.png",
  "THEME_04_Base.png",
  "THEME_05_Base.png",
];

export const graphicsThumbnails = graphics.map(
  (image) => `/textures/${image.replace(".png", "_Thumb.png")}`
);

export const texture = (image) =>
  useLoader(TextureLoader, `/textures/${image}`);

export const state = proxy({
  current: null,
  items: config.CAR.items,
});

export const selectionState = proxy({
  current: null,
  items: { ...config.CAR.editableItems },
});

export const patternState = proxy({
  current: null,
  items: graphics,
});

export const perspective = (fov, aspect, near, far) => {
  const f = 1.0 / Math.tan(fov / 2);
  const nf = 1.0 / (near - far);

  return [
    f / aspect,
    0,
    0,
    0,
    0,
    f,
    0,
    0,
    0,
    0,
    (far + near) * nf,
    -1,
    0,
    0,
    2 * far * near * nf,
    0,
  ];
};

export const turnOnLights = async (ip) => {
  console.log("turnOnLights");
  try {
    await fetch(`http://${ip}/color/0?turn=on`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    // console.error('Error turning on lights:', error);
  }
};

export const turnOffLights = async (ip) => {
  console.log("turnOffLights");
  try {
    await fetch(`http://${ip}/color/0?turn=off`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    // console.error('Error turning on lights:', error);
  }
};

export const toMatrix4 = (matrix) => {
  const mat4 = new THREE.Matrix4();
  mat4.fromArray(matrix);
  return mat4;
};

export const scaleValue = (value, from, to) => {
  var scale = (to[1] - to[0]) / (from[1] - from[0]);
  var capped = Math.min(from[1], Math.max(from[0], value)) - from[0];

  return ~~(capped * scale + to[0]);
};
export const cropImage = async (screenshotDataURL) => {
  const offsetX = -600; // Start cropping from the beginning of the image
  const offsetY = -100; // Start cropping from the beginning of the image

  const targetDPI = 300;
  const canvasWidth = targetDPI * 17;
  const canvasHeight = targetDPI * 11;

  return new Promise((resolve, reject) => {
    const screenshotImage = new Image();
    screenshotImage.onload = () => {
      const backgroundImage = new Image();
      backgroundImage.onload = () => {
        // Create a canvas to draw the rotated image
        const tempCanvas = document.createElement("canvas");
        const tempCtx = tempCanvas.getContext("2d");
        tempCanvas.width = canvasHeight; // Switch width and height for rotation
        tempCanvas.height = canvasWidth;

        // Translate and rotate the canvas
        tempCtx.translate(tempCanvas.width / 2, tempCanvas.height / 2);
        tempCtx.rotate(-Math.PI / 2); // Rotate counter-clockwise by 90 degrees
        tempCtx.translate(-canvasWidth / 2, -canvasHeight / 2);

        // Draw the background image on the temporary canvas
        tempCtx.drawImage(backgroundImage, 0, 0, canvasWidth, canvasHeight);

        const scaleFactor = Math.min(
          canvasWidth / screenshotImage.width,
          canvasHeight / screenshotImage.height
        );
        const scaledWidth = screenshotImage.width * scaleFactor;
        const scaledHeight = screenshotImage.height * scaleFactor;
        const centerX = (canvasWidth - scaledWidth) / 2;
        const centerY = (canvasHeight - scaledHeight) / 2;

        // Draw the cropped and scaled screenshot
        tempCtx.drawImage(
          screenshotImage,
          offsetX,
          offsetY,
          screenshotImage.width,
          screenshotImage.height,
          centerX,
          centerY,
          scaledWidth,
          scaledHeight
        );

        // Create the final canvas
        const finalCanvas = document.createElement("canvas");
        const finalCtx = finalCanvas.getContext("2d");
        finalCanvas.width = canvasHeight; // Switch width and height for the final canvas
        finalCanvas.height = canvasWidth;

        // Draw the rotated image from the temporary canvas to the final canvas
        finalCtx.drawImage(tempCanvas, 0, 0);

        resolve(finalCanvas.toDataURL("image/png"));
      };
      backgroundImage.onerror = reject;
      backgroundImage.src = "background.png";
    };
    screenshotImage.onerror = reject;
    screenshotImage.src = screenshotDataURL;
  });
};

export const isSignificantChange = (prevValue, newValue, threshold) => {
  const difference = Math.abs(newValue - prevValue);
  return difference >= threshold && difference <= 20;
};

export const deg2rad = (degrees) => degrees * (Math.PI / 180);

export const rad2deg = (radians) => radians * (180 / Math.PI);

const defaultVideoWidth = 640;

export const onFaceMove = (cameraControls, faceX, leftEyeYPosition, zPos) => {
  // Manually fire the event in OrbitControls
  cameraControls.handleFaceMoveRotate(faceX, leftEyeYPosition, zPos);
};

export const getFaceCoordinates = (cameraControls, pos) => {
  const scaledXCoordinate = scaleValue(
    pos.x,
    [0, defaultVideoWidth],
    [0, window.innerWidth]
  );

  const position = window.innerWidth - scaledXCoordinate;
  const yPosition = pos.y;

  onFaceMove(cameraControls, position, yPosition, pos.z);
};

export const rotatePointAroundXAxis = (x, y, z, angle) => {
  const tiltAngleRadians = deg2rad(angle);
  const originalVector = new THREE.Vector3(x, y, z);
  const rotation = new THREE.Euler(tiltAngleRadians, 0, 0, "XYZ");
  const rotatedVector = originalVector.applyEuler(rotation);

  return { x: rotatedVector.x, y: rotatedVector.y, z: rotatedVector.z };
};
export const rotatePointAroundXAxisUsingQuaternion = (x, y, z, angle) => {
  // Convert the angle from degrees to radians
  const angleRadians = THREE.MathUtils.degToRad(angle);

  // Create the point as a THREE.Vector3
  const point = new THREE.Vector3(x, y, z);

  // Create a quaternion for rotation around the X-axis
  const quaternion = new THREE.Quaternion();
  quaternion.setFromAxisAngle(new THREE.Vector3(1, 0, 0), angleRadians);

  // Apply the quaternion to the point
  const rotatedPoint = point.applyQuaternion(quaternion);

  return rotatedPoint;
};
