export const config = {
  CAR: {
    position: {
      x: 5,
      y: 59.5,
      z: -95,
    },
    scaleOffset: -0.03,
    scaleXOffset: 0,
    scale: 0.735,
    rotation: {
      x: 0, // pitch
      y: 0, // yaw
      z: 0, // roll
    },
    opacity: 8,
    fixedMeshItems: ["REARWING_STATIC"],
    fixedColor: ["WHEELS_LOGO"],
    meshItems: [
      "BODY_INSIDE",
      "BODY",
      "FLOOR",
      "FRONTWING",
      "INTAKE",
      "REARWING",
    ],
    clickableMeshItems: [
      "REARWING_STATIC",
      "BODY_INSIDE",
      "BODY",
      "FLOOR",
      "FRONTWING",
      "INTAKE",
      "REARWING",
    ],
    logoMeshItems: [
      "BODY_LOGO",
      "FLOOR_LOGO",
      "FRONTWING_LOGO",
      "INTAKE_LOGO",
      "REARWING_LOGO",
    ],
    names: {
      "BODY.001": "BODY",
      "BODY.002": "BODY_INSIDE",
      "FLOOR.001": "FLOOR",
      "FRONTWING.001": "FRONTWING",
      "INTAKE.001": "INTAKE",
      "REARWING.001": "REARWING",
      "WHEELS.001": "WHEELS",
      "BODY_LOGO.001": "BODY_LOGO",
      "FLOOR_LOGO.001": "FLOOR_LOGO",
      "FRONTWING_LOGO.001": "FRONTWING_LOGO",
      "INTAKE_LOGO.001": "INTAKE_LOGO",
      "REARWING_LOGO.001": "REARWING_LOGO",
    },
    editableItems: {
      body: ["BODY.001", "BODY.002"],
      floor: ["FLOOR.001"],
      "front wing": ["FRONTWING.001"],
      intake: ["INTAKE.001"],
      "rear wing": ["REARWING.001"],
      "whole car": [
        "BODY.001",
        "BODY.002",
        "FLOOR.001",
        "FRONTWING.001",
        "INTAKE.001",
        "REARWING.001",
      ],
    },
    editableLabels: {
      "BODY.001": "body",
      "BODY.002": "body",
      "FLOOR.001": "floor",
      "FRONTWING.001": "front wing",
      "INTAKE.001": "intake",
      "REARWING.001": "rear wing",
    },
    editableGraphics: {
      body: ["BODY_LOGO.001"],
      floor: ["FLOOR_LOGO.001"],
      "front wing": ["FRONTWING_LOGO.001"],
      intake: ["INTAKE_LOGO.001"],
      "rear wing": ["REARWING_LOGO.001"],
    },
    nonConfigurableItems: ["NONCONFIGTIRES", "NONCONFIG"],
    items: {
      "BODY.001": "#333",
      "BODY.002": "#333",
      "FLOOR.001": "#333",
      "FRONTWING.001": "#333",
      "INTAKE.001": "#333",
      "REARWING.001": "#333",
      "WHEELS.001": "#000000",
    },
    logoItems: {
      "BODY_LOGO.001": "#e7e576",
      "FLOOR_LOGO.001": "rgb(199, 174, 0)",
      "FRONTWING_LOGO.001": "rgb(199, 174, 0)",
      "INTAKE_LOGO.001": "rgb(199, 174, 0)",
      "REARWING_LOGO.001": "rgb(199, 174, 0)",
    },
    materials: {
      BODY: "BODY.001",
      BODY_INSIDE: "BODY.002",
      FLOOR: "FLOOR.001",
      FRONTWING: "FRONTWING.001",
      INTAKE: "INTAKE.001",
      REARWING: "REARWING.001",
      BODY_LOGO: "BODY_LOGO.001",
      FLOOR_LOGO: "FLOOR_LOGO.001",
      FRONTWING_LOGO: "FRONTWING_LOGO.001",
      INTAKE_LOGO: "INTAKE_LOGO.001",
      REARWING_LOGO: "REARWING_LOGO.001",
      WHEELS: "WHEELS.001",
    },
    materialItems: {
      "BODY.001": "BODY",
      "BODY.002": "BODY",
      "FLOOR.001": "FLOOR",
      "FRONTWING.001": "FRONTWING",
      "INTAKE.001": "INTAKE",
      "REARWING.001": "REARWING",
    },
    materialLabels: {
      BODY: "body",
      BODY_INSIDE: "body",
      FLOOR: "floor",
      FRONTWING: "front wing",
      INTAKE: "intake",
      REARWING: "rear wing",
    },
    logoMaterials: {
      BODY_LOGO: "BODY_LOGO.001",
      FLOOR_LOGO: "FLOOR_LOGO.001",
      FRONTWING_LOGO: "FRONTWING_LOGO.001",
      INTAKE_LOGO: "INTAKE_LOGO.001",
      "REARWING_LOGO ": "REARWING_LOGO .001",
    },
  },
};
